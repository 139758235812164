// Pagination

.pagination {
  // default look for page item, firsy and last item

  .page-item {
    border-radius: 5rem;
    &.prev-item,
    &.next-item,
    &.first,
    &.last,
    &.prev,
    &.next {
      .page-link {
        color: $primary;
      }

      &.disabled {
        .page-link {
          color: $secondary;
        }
      }
    }

    // prev and last item styles

    &.prev-item {
      margin-right: 0.3571rem;
      .page-link {
        background-color: $pagination-bg-color;
        padding: $pagination-action-padding-y $pagination-action-padding-x;
        border-radius: 50%;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      & ~ .page-item:nth-child(2) {
        &.active {
          .page-link {
            border-radius: 0.5rem !important;
          }
        }
        .page-link {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }

      & ~ .page-item:nth-last-child(-n + 2) {
        &.active {
          .page-link {
            border-radius: 0.5rem !important;
          }
        }
        .page-link {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    &.next-item {
      margin-left: 0.3571rem;

      .page-link {
        background-color: $pagination-bg-color;
        padding: $pagination-action-padding-y $pagination-action-padding-x;
        border-radius: 50%;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        &:hover {
          background: $primary;
          color: $white;
        }
      }
    }

    // page link customization

    .page-link {
      border: none;
      margin: 0;
      color: rgba($pure-black, 0.6);
      background-color: $pagination-bg-color;
      margin-left: 0;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.4rem;

      svg {
        height: 1.2rem;
        width: 1.2rem;
        position: relative;
        top: -1px;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: $primary;
      }
    }

    // for active page item
    &.active {
      background-color: $pagination-bg-color;
      border-radius: 0;

      .page-link {
        z-index: 3;
        border-radius: 0.5rem;
        background-color: $primary;
        color: $white;
        transform: scale(1.05);
      }
    }

    // if you dont want seprated look

    &:first-child:not(.prev-item) {
      &.active {
        border-top-left-radius: 1.428rem;
        border-bottom-left-radius: 1.428rem;
        .page-link {
          border-radius: 5rem;
        }
      }
      .page-link {
        border-top-left-radius: 1.428rem;
        border-bottom-left-radius: 1.428rem;
      }
    }

    &:last-child:not(.next-item) {
      &.active {
        border-top-right-radius: 1.428rem;
        border-bottom-right-radius: 1.428rem;
        .page-link {
          border-radius: 5rem;
        }
      }
      .page-link {
        border-top-right-radius: 1.428rem;
        border-bottom-right-radius: 1.428rem;
      }
    }
  }

  // For Pagination lg

  &.pagination-lg {
    .page-item {
      .page-link {
        font-size: $pagination-font-size-lg;
        transform: scale(1);
      }
    }
  }
}
