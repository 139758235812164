@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "./assets/scss/app.scss";

html {
    font-family: 'Poppins', 'feather';
}

.dropdown-menu button {
    display: block !important;
    width: 100% !important;
}

* {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}


@media (max-width: 801px) {
    .card-body.scroll {
        overflow-x: auto;
        overflow-y: hidden;

        .btn.add {
            display: flex;
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .btn.filter {
            margin-right: 10px;
        }
    }
}

.pointer {
    cursor: pointer;
}

.btn-sm {
    font-size: 12px;
}