/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "~bootstrap/scss/_functions.scss";
@import "core/variables/variables";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
