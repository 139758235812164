.collapse-icon {
  .card-header {
    position: relative;
  }

  .card-header {
    &:after {
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: "feather";
      content: "\e845";
      transition: all 300ms linear 0s;
      font-size: 0.9rem;
    }

    &:before {
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: "feather";
      content: "\e842";
      transition: all 300ms linear 0s;
      font-size: 0.9rem;
    }
  }

  &.left {
    a {
      padding-left: 28px;

      &:before,
      &:after {
        left: 20px;
        right: auto;
      }
    }
  }

  // .card-header {
  //   &:before {
  //     opacity: 0;
  //   }
  // }

  // .card-header {
  //   &:after {
  //     opacity: 0;
  //   }
  // }

  &.accordion-icon-rotate {
    .collapse.show {
      &:before {
        transform: rotate(180deg);
      }
    }

    .collapse:not(.show) {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}
