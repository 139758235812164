// =========================================================================================
//   File Name: chat-widget.scss
//   Description: Custom vuexy chat-widget component.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: chat-widget
// ========================================================================

.chat-widget {
  .chat-app-window {
    .user-chats {
      padding: 10px 20px;
      position: relative;
      text-align: center;
      height: 415px;
      // Chat area css
      .chats {
        padding: 0;
        .chat-body {
          display: block;
          margin: 10px 30px 0 0;
          overflow: hidden;
          .chat-content {
            text-align: left;
            display: block;
            float: right;
            padding: 0.75rem 1rem;
            margin: 0 20px 10px 0;
            clear: both;
            color: $white;
            background: linear-gradient(
              118deg,
              rgba($primary, 1),
              rgba($primary, 0.7)
            );
            border-radius: 0.5rem;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
              0 2px 4px 0 rgba(0, 0, 0, 0.08);
            p {
              margin: 0;
            }
          }
        }
        .chat-avatar {
          float: right;
        }
        // Chat area left css
        .chat-left {
          .chat-avatar {
            float: left;
          }
          .chat-body {
            margin: 0;
          }
          .chat-content {
            text-align: left;
            float: left;
            margin: 10px 10px 10px 20px;
            color: $body-color;
            background: none;
            background-color: lighten($white, 18%);
            border: 1px solid $gray-300;
          }
        }
      }
    }
  }
}